<template>
  <div class="container">
    <div class="title">
      <span>针对手术部位感染的了解</span>
    </div>
    <div class="banner">
      <img src="@/assets/images/plus20.jpg" alt="" srcset="">
    </div>
    <div class="question">
      <p>1.您是否认同手术部位感染患者的高风险因素（糖尿病、高龄、吸烟、肥胖、合并基础疾病等）？</p>
      <div class="answerBox">
        <input class="input" type="text" v-model.number="answer1" @blur="answer1Blur">
        <div class="sliderBox">
          <span class="describe describe_left">不认同(0)</span>
          <span class="describe describe_right">非常认同(10)</span>
          <van-slider class="vanSlider" v-model="answer1" :min="0" :max="10" :step="1" bar-height="4px" button-size="20px" />
        </div>
      </div>
    </div>
    <div class="question">
      <p>2.您是否了解“SSI组合式管理（SSI Bundle）”整体解决方案以降低手术部位感染风险？（SSI组合式管理：术前术中术后使用多种行之有效的干预措施）</p>
      <div class="answerBox">
        <input class="input" type="text" v-model="answer2" @blur="answer2Blur">
        <div class="sliderBox">
          <span class="describe describe_left">不认同(0)</span>
          <span class="describe describe_right">非常认同(10)</span>
          <van-slider class="vanSlider" v-model="answer2" :min="0" :max="10" :step="1" bar-height="4px" button-size="20px" />
        </div>
      </div>
    </div>
    <div class="question">
      <p>3.您是否认同“抗菌缝线”是降低手术部位感染（SSI）风险的重要举措之一？</p>
      <div class="answerBox">
        <input class="input" type="text" v-model="answer3" @blur="answer3Blur">
        <div class="sliderBox">
          <span class="describe describe_left">不认同(0)</span>
          <span class="describe describe_right">非常认同(10)</span>
          <van-slider class="vanSlider" v-model="answer3" :min="0" :max="10" :step="1" bar-height="4px" button-size="20px" />
        </div>
      </div>
    </div>
    <div class="question">
      <p>4.您所在的科室？</p>
      <div class="answerBox">
        <div class="inputBox" @click="showDepartmentPicker = true">
          <div class="selectInput">
            <input
              type="text"
              v-model="department"
              placeholder="请选择科室"
            />
            <div></div>
          </div>
          <img class="pulldownIcon" src="@/assets/images/triangle@2x.png" />
        </div>
        <van-popup v-model="showDepartmentPicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="DepartmentColumns"
            value-key="Name"
            @cancel="showDepartmentPicker = false"
            @confirm="onDepartmentConfirm"
          />
        </van-popup>
      </div>
    </div>
    <div class="submit" @click="submit">提交</div>
  </div>
</template>

<script>
import utils from "../../common/utils/utils";
export default {
  name: 'SSIquestionnaire',
  data() {
    return {
      answer1: 0,
      answer2: 0,
      answer3: 0,
      department: '',
      departmentCode: '',
      DepartmentColumns: [],
      showDepartmentPicker: false
    }
  },
  created() {
    this.getDepartment();
  },
  methods: {
    getDepartment() {
      this.$axios.post('/Api/Api/Web/GetWxUser/GetDitWebCode?type=1&pcode=').then(res => {
        this.DepartmentColumns = res.Data;
      })
    },
    onDepartmentConfirm(value) {
      this.department = value.Name;
      this.departmentCode = value.code;
      this.showDepartmentPicker = false;
    },
    answer1Blur() {
      this.answer1 = this.answer1 != '' ? this.answer1 : 0;
    },
    answer2Blur() {
      this.answer2 = this.answer2 != '' ? this.answer2 : 0;
    },
    answer3Blur() {
      this.answer3 = this.answer3 != '' ? this.answer3 : 0;
    },
    submit: utils.debounce(function() {
      if (!this.department || !this.departmentCode) {
        this.$toast('请选择科室！');
        return false;
      }

      this.$axios.post('/Api/Api/Web/Questionnaire/Add', {
        'HighRisk': this.answer1,
        'InfectionRisk': this.answer2,
        'PositionRisk': this.answer3,
        'Code': this.departmentCode,
        'Name': this.department
      }).then(res => {
        if (res.RetCode == '10000') {
          this.answer1 = 0;
          this.answer2 = 0;
          this.answer3 = 0;
          this.departmentCode = '';
          this.department = '';
          this.$dialog.alert({
            message: "提交成功！",
          }).then(() => {
            this.$router.go(-1);
          });
        } else {
          this.$toast(res.RetMsg);
        }
      })
    }, 1000)
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  padding: 10px;
  box-sizing: border-box;
  .title {
    text-align: center;
    font-size: 18px;
    color: #CA001B;
    font-weight: bolder;
    margin: 5px 0 15px;
  }
  .banner {
    img {
      width: 100%;
    }
  }
  .question {
    margin: 10px 0;
  }
  .answerBox {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 25px;
  }
  .input {
    width: 40px;
    height: 28px;
    border: 1px solid #cccccc;
    margin-right: 15px;
    text-align: center;
    color: #606266;
  }
  .sliderBox {
    position: relative;
    flex: 1;
    .vanSlider {
      margin-top: 14px;
    }
    .describe {
      position: absolute;
      font-size: 12px;
      color: #999999;
    }
    .describe_left {
      left: 0;
      top: -10px;
    }
    .describe_right {
      right: 0;
      top: -10px;
    }
  }
  .inputBox {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 10px 10px;
    border: 1px solid #cccccc;
    .selectInput {
      position: relative;
      flex: 1;
      div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    input {
      flex: 1;
      padding: 0 6px;
      border: none;
      box-sizing: border-box;
      background: transparent;
      color: #333333 !important;
    }
    .searchIcon {
      width: 18px;
      height: 18px;
      padding: 0 8px;
    }
    .pulldownIcon {
      width: 16px;
      padding: 0 8px;
    }
  }
  .submit {
    width: 100%;
    height: 48px;
    line-height: 48px;
    text-align: center;
    color: #ffffff;
    border: none;
    background-color: #CA001B;
    border-radius: 4px;
    margin: 15px 0 20px;
  }
}
</style>
